import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiAuthResponse, ApiResponse } from "../interfaces/interfaces";

export function isAuthenticated(): boolean {
  if (sessionStorage.getItem("admin_token")) {
    return true;
  } else {
    return false;
  }
}

export function getApiInstance(): AxiosInstance {
  const payload = {
    baseURL: process.env.REACT_APP_ACCOUNT_API_URL,
  } as AxiosRequestConfig<any>;
  const token = sessionStorage.getItem("auth_code");
  if (token) {
    // jika ada token di session
    payload.headers = {
      authorization: "Bearer " + token,
    };
  }
  const instance = axios.create(payload);
  return instance;
}

export function getAdminApiInstance(): AxiosInstance {
  const payload = {
    baseURL: process.env.REACT_APP_ACCOUNT_API_URL,
  } as AxiosRequestConfig<any>;
  const token = sessionStorage.getItem("admin_token");
  if (token) {
    // jika ada token di session
    payload.headers = {
      authorization: "Bearer " + token,
    };
  }
  const instance = axios.create(payload);
  return instance;
}

export async function login(authCode: string) {
  const instance = getApiInstance();
  try {
    const formData = new FormData();
    formData.set("auth_code", authCode);
    const rawResponse = await instance.post("admin/auth", formData);
    const response: ApiResponse<ApiAuthResponse> = rawResponse.data;
    sessionStorage.setItem("admin_token", response.token!);
    localStorage.setItem("person_id", response.result.mygms_id);
    return true;
  } catch (err) {
    console.error("Login failed");
    console.error(err);
    return false;
  }
}

export async function logout() {
  sessionStorage.removeItem("admin_token");
  localStorage.removeItem("person_id");
  window.location.href = "/";
}

export function isUserAuthenticated(): boolean {
  if (sessionStorage.getItem("auth_code")) {
    return true;
  } else {
    return false;
  }
}

export async function createForm(
  data: {
    fullname: String;
    dob: String;
    email: String;
    gender: String;
    phone: String;
    address: String;
    cg_status: String;
    gms_id: String;
    bill_no?: String;
    src?: String;
  },
  url: string
) {
  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    console.error("Create Form failed");
    console.error(error);
  }
}
