import './register-page.scss'
import React, { useEffect, useState } from 'react'
import { getApiInstance, isUserAuthenticated } from '../utils/helper'
import moment from 'moment'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { createForm } from '../utils/helper'
import Profile from '../components/Profile'
import { stringify } from 'querystring'
import { url } from 'inspector'
import { DetailForm } from '../interfaces/detail_form'
const background = require('../img/MLC-BG.jpg')
const background_mobile = require('../img/MLC-BG2.jpg')
const tutorial_1 = require('../img/1.png')
const tutorial_2 = require('../img/2.png')

declare global {
  interface Window {
    opera: any
  }
}

type Transaction = {
  bill_no: string
  bill_total: number
  bill_at: string
  expired_at: string
  paid_at: string
  payment_url: string
  payment_channel_id: number
  payment_channel_name: string
  payment_receipt_url: string
  status: string
}

type RegistrationStatus = {
  registered: boolean
  transaction: Transaction
}

type Registrant = {
  mygms_id: string
  name: string
  birth_date: string
  cell_phone: string
  email: string
  registration_status?: RegistrationStatus
}

const eventData = [
  {
    event_code: 'JABODETABEK',
    event_name: 'KIDS CAMP 2023 - 15 Juni 2023',
    event_description: '15 Juni 2023',
    event_closing: '10 Juni 2023',
    event_pre_register: 'Sabtu, 17 Juni 2023, ??.?? WIB',
  },
]

function RegisterPage() {
  const [registrant, setRegistrant] = useState({} as Registrant)
  const [isLoading, setIsLoading] = useState(true)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)
  const [isRegistered, setIsRegistered] = useState(true)
  const [detailForms, setDetailForms] = useState<DetailForm[]>([
    {
      index: 0,
      name: '',
      grade: '',
      gender: '',
      cell_phone: '',
    },
  ])
  const [userDataParams, setUserDataParams] = useState<{
    address: string
    connect_groups: Array<Object>
    gender: string
    event_code: string
    church: {
      name: string
    }
  }>({
    address: '',
    connect_groups: [],
    gender: '',
    event_code: '',
    church: {
      name: '',
    },
  })
  const [churchLocation, setChurchLocation] = useState('')
  const [isQuotaAvailable, setIsQuotaAvailable] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [eventCodeState, setEventCodeState] = useState('')

  const [occupation, setOccupation] = useState('')
  const [province, setProvince] = useState('')
  const [district, setDistrict] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [cityCustom, setCityCustom] = useState('')
  const [pickUp, setPickUp] = useState('')
  const [total, setTotal] = useState(1)

  const doRelogin = () => {
    window.location.href = `https://accounts.gms.church/oauth/authorize?scope=profile,contact,certificates,cg,cg-contact&state=state&client_id=36d5b3e629f29f9f802e3c791efb84cb&redirect_uri=${process.env.REACT_APP_GMS_OAUTH_CALLBACK}`
  }

  const handleCustomBe = async (e: any) => {
    //hit custom BE
    const data: any = buildParamsForms(userDataParams)
    data.event_code = eventCodeState
    data.bill_no = ''
    data.src = 'btn-register' //identifier when hit url need create transaction or not
    if (
      registrant.registration_status?.registered &&
      registrant.registration_status?.transaction.bill_no &&
      !registrant.registration_status?.transaction?.bill_no?.includes('GMS/EK-JBT/23')
    ) {
      data.bill_no = registrant.registration_status.transaction.bill_no
    }
    const form = await createForm(
      data,
      process.env.REACT_APP_BE_URL + '/forms/create'
    )
  }
  const handleRegister = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      //hit custom BE
      const data: any = buildParamsForms(userDataParams)
      data.bill_no = ''
      data.src = 'btn-register' //identifier when hit url need create transaction or not
      data.event_code = eventCodeState
      if (
        registrant.registration_status?.registered &&
        registrant.registration_status?.transaction.bill_no &&
        !registrant.registration_status?.transaction?.bill_no?.includes('GMS/EK-JBT/23')
      ) {
        data.bill_no = registrant.registration_status.transaction.bill_no
      }

      const form = await createForm(
        data,
        process.env.REACT_APP_BE_URL + '/forms/create'
      )

      // redirect ke halaman yang dituju
      if (form && form.status == 200 && form.data.redirect_url) {
        window.location = form?.data.redirect_url
      } else if (
        form &&
        form.data.status == 422 &&
        form.data.flag != 'registered'
      ) {
        Swal.fire('Error', form.data.message, 'error')
        setIsLoading(false)
      } else {
        Swal.fire('Error', 'Error silahkan hubungi hotline kami', 'error')
      }
    } catch (error: any) {
      Sentry.captureException(error)
      if (error.response) {
        Swal.fire('Error', error.response.data.error, 'error')
      } else {
        Swal.fire('Error', 'Terjadi kesalahan!', 'error')
      }
      setIsLoading(false)
    }
  }
  // handler upload bukti pembayaran
  const handleImageUpload = async (e: any) => {
    const file = e.target.files[0]
    const imageType = file.type
    const supportedTypes = ['image/jpeg', 'image/png']
    if (!supportedTypes.includes(imageType)) {
      Swal.fire(
        'Format gambar tidak didukung',
        'Silahkan pilih file dengan format .jpg, .jpeg, atau .png.',
        'warning'
      )
      return
    }
    Swal.fire({
      title: 'Upload bukti pembayaran?',
      icon: 'question',
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      preConfirm: () => {
        var formData = new FormData()
        formData.append('file', file)
        formData.append(
          'bill_no',
          registrant.registration_status!.transaction.bill_no
        )
        const instance = getApiInstance()
        return instance
          .post('me/payment/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((result: any) => {
            if (result.status === 200) {
              // update data yang sudah terupload
              const tempRegistrant = registrant
              registrant.registration_status!.transaction.payment_receipt_url =
                result.data.image_url
              setRegistrant({ ...tempRegistrant })
              Swal.fire({
                title: 'Bukti pembayaran berhasil di upload',
                imageUrl: result.data.image_url,
                icon: 'success',
              })
            }
          })
          .catch((err) => {
            console.error('Error at image upload!')
            console.error(err)
            Swal.fire({
              title: 'Gagal melakukan upload bukti pembayaran!',
              text: 'Silahkan mencoba beberapa saat lagi atau coba ulang dengan ukuran gambar yang lebih kecil.',
              icon: 'error',
            })
          })
      },
    })
  }
  // Labeling status pembayaran
  const getBadgeType = (status: string) => {
    switch (status) {
      case 'EXPIRED':
      case 'CANCELED':
        return 'bg-danger'
      case 'WAITING':
        return 'bg-warning'
      case 'PAID':
        return 'bg-success'
      default:
        return 'bg-secondary'
    }
  }
  const renderRegistrationStatus = (transaction: Transaction) => {
    let label =
      transaction.payment_channel_id === 5
        ? 'Ganti metode pembayaran'
        : 'Lanjutkan pembayaran'
    return (
      <div>
        {(transaction.status === 'EXPIRED' ||
          transaction.status === 'CANCELED') && (
            <div>
              <div className="alert alert-danger">
                {transaction.status === 'CANCELED' &&
                  'Pendaftaran Anda telah dibatalkan. Segera hubungi hotline kami jika Anda tidak merasa melakukan pembatalan.'}
                {transaction.status === 'EXPIRED' &&
                  'Pendaftaran Anda telah melewati batas waktu. Jika Anda telah melakukan pembayaran tetapi belum di verifikasi, silahkan hubungi hotline dengan menyertakan bukti pembayarandan bill number Anda.'}
              </div>
            </div>
          )}
        <div className="card">
          <div className="card-body">
            {transaction.status === 'WAITING' &&
              transaction.payment_channel_id === 5 && (
                <div className="alert alert-primary">
                  Pembayaran melalui BCA Virtual Account akan di proses dalam
                  3x24jam di hari kerja setelah pembayaran dilakukan. <br />
                  <br />
                  Jika Anda mengalami kendala silahkan hubungi Call Center di{' '}
                  <a href="https://wa.me/628152120080?target=_blank">
                    0815-2120-080
                  </a>{' '}
                  (WA Only)
                </div>
              )}
            {transaction.status === 'PAID' && (
              <div></div>
            )}

            <div className="form-group">
              <label>Tanggal Registrasi</label>
              <div className="form-control-static">
                {moment(transaction.bill_at).format('D MMM YYYY HH:mm')}
              </div>
            </div>
            <div className="form-group">
              <label>Bill No.</label>
              <div className="form-control-static">{transaction.bill_no}</div>
            </div>

            <div className="form-group">
              <label>Total Bayar</label>
              <div className="form-control-static">
                <b style={{ fontSize: '1.2em' }}>
                  {new Intl.NumberFormat('id').format(transaction.bill_total)}
                </b>
              </div>
            </div>
            <div className="form-group">
              <label>Status</label>
              <div className="form-control-static">
                <span className={'badge ' + getBadgeType(transaction.status)}>
                  {transaction.status}
                </span>
              </div>
            </div>
            {transaction.status === 'WAITING' && (
              <div>
                <div className="form-group">
                  <label>Batas waktu pembayaran</label>
                  <div className="form-control-static">
                    <b>
                      {moment(transaction.expired_at).format(
                        'D MMM YYYY HH:mm'
                      )}
                    </b>
                  </div>
                </div>
                {transaction.payment_channel_id === 5 && (
                  <div className="va-wrapper">
                    <div>Silahkan melakukan pembayaran melalui:</div>
                    <br />
                    <div>BCA Virtual Account</div>
                    <div>GRJ MAWARSHARON</div>
                    <div>
                      <span className="va-number">50777070001900</span>
                    </div>
                    <div className="mt-4">
                      {/* munculkan gambar bila sudah pernah ada */}
                      {transaction.payment_receipt_url && (
                        <div className="mb-2">
                          <img
                            src={transaction.payment_receipt_url}
                            alt="receipt"
                            className="w-100"
                          />
                        </div>
                      )}
                      {/* tombol upload hanya muncul bila gambar belum pernah di upload */}
                      {!transaction.payment_receipt_url && (
                        <div>
                          Upload bukti pembayaran Anda
                          <input
                            type="file"
                            onChange={handleImageUpload}
                            accept="image/*"
                          />
                        </div>
                      )}
                      <div></div>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <a
                    href={transaction.payment_url}
                    className="btn btn-warning w-100"
                    onClick={handleCustomBe}
                  >
                    {label}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  function buildParamsForms(data: any) {
    let converted = {
      fullname: '',
      dob: '',
      email: '',
      gender: '',
      phone: '',
      address: '',
      cg_status: '',
      gms_id: '',
      church: '',
      event_code: '',
      is_sync: false,
      province: '',
      district: '',
      postal_code: '',
      city: '',
      pick_up: '',
      details: [
        {
          fullname: '',
          grade: '',
          gender: '',
          phone: '',
        },
      ],
    }
    try {
      const church = getChurchLocation(data.church.church_id)
      const connect_groups = getCg(data.connect_groups)
      const event_code = getEventCode(churchLocation, data.connect_groups)
      setEventCodeState(event_code)

      converted = {
        fullname: data.name,
        dob: data.birth_date,
        email: data.email.address,
        gender: data.gender,
        phone: data.cell_phone.number,
        address: data.address,
        cg_status: connect_groups,
        gms_id: data.mygms_id,
        church: church,
        event_code: event_code,
        is_sync: data.is_sync,
        province: province,
        district: district,
        postal_code: postalCode,
        city: city,
        pick_up: pickUp,
        details: detailForms.map(function (detailForm) {
          return {
            fullname: detailForm.name,
            gender: detailForm.gender,
            grade: detailForm.grade,
            phone: detailForm.cell_phone,
          }
        }),
      }
    } catch (error) {
      doRelogin()
    }
    return converted
  }

  function getCg(data: any) {
    let res = 'BELUM MEMPUNYAI CG'
    if (data) {
      res =
        data.filter((cg: any) => cg.category.toLowerCase() == 'pro-m').length >=
          1
          ? 'PRO-M'
          : 'AOG YOUTH'
      if (res == 'AOG YOUTH') {
        res =
          data.filter((cg: any) => cg.category.toLowerCase() == 'family')
            .length >= 1
            ? 'FAMILY'
            : 'AOG YOUTH'
      }
      if (res == 'AOG YOUTH') {
        res =
          data.filter(
            (cg: any) =>
              cg.category.toLowerCase() == 'aog teens' ||
              cg.category.toLowerCase() == 'kidz'
          ).length >= 1
            ? 'AOG TEENS'
            : 'AOG YOUTH'
      }
    }

    return res
  }

  function getChurchLocation(churchId: string): any {
    let churchName = null;

    if (churchId === '8f4c6a26b91c743ac3168a8f7c00fd99') {
      churchName = 'JAKARTA BARAT'
    }

    if (churchId === '421145765f0055368d0b319e7d0382a8') {
      churchName = 'TANGERANG'
    }

    if (churchId === 'a360265027fc7ba3f08938443bd61088') {
      churchName = 'BOGOR'
    }

    if (churchId === '61df7b4dedd41afd950e06851dd7f281') {
      churchName = 'KELAPA GADING'
    }

    if (churchId === '61df7b4dedd41afd950e06851dd7dab0') {
      churchName = 'CIKARANG'
    }

    if (churchId === 'b7155e62398ef052f499e020f3bac1b6') {
      churchName = 'BEKASI'
    }

    if (churchId === '8f4c6a26b91c743ac3168a8f7c0195fa') {
      churchName = 'BANDUNG'
    }

    if (churchId === '8f4c6a26b91c743ac3168a8f7c01c17f') {
      churchName = 'CIREBON'
    }

    if (churchId === '8f4c6a26b91c743ac3168a8f7c01ab54') {
      churchName = 'SUKABUMI'
    }

    return churchName;

    // return church === '421145765f0055368d0b319e7d0382a8' ||
    //   church == 'TANGERANG'
    //   ? 'TANGERANG'
    //   : 'JAKARTA'
  }

  function getEventCode(c: string, cg_data: any): string {
    const church = getChurchLocation(c)
    let cg = cg_data
    if (typeof cg == 'object') cg = getCg(cg_data)
    return 'JABODETABEK'
  }

  const isMobileDevice = function () {
    let check = false
      ; (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
    return check
  }
  useEffect(() => {
    if (occupation != '') {
      const cg = occupation == 'Pelajar' ? 'AOG TEENS' : 'AOG YOUTH'
      const event_code = getEventCode(churchLocation, cg)
      setEventCodeState(event_code)
    } else {
      setEventCodeState(
        getEventCode(churchLocation, userDataParams.connect_groups)
      )
    }
  }, [occupation, churchLocation, userDataParams])

  useEffect(() => {
    const getData = async () => {
      const instance = getApiInstance()
      try {
        //NEED IMPROVEMENT 3x hit api to 1x
        var rawResponse = await instance.get('/me/profile')
      } catch (exc: any) {
        const err = exc.toJSON()
        if (err.status === 401) {
          // perlu login ulang
          doRelogin()
        }
        return
      }

      //get data
      const res = await axios.get(
        //NEED IMPROVEMENT 3x hit api to 1x
        process.env.REACT_APP_ACCOUNT_2_API_URL + '/me',
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('auth_code')}`,
          },
        }
      )

      const userData = res.data.result
      setUserDataParams(userData)

      const queryParams = new URLSearchParams({
        gms_id: userData.mygms_id,
        type: 'gms_id',
      }).toString()
      const formReq = await axios.get(
        process.env.REACT_APP_BE_URL + '/forms/find?' + queryParams
      )
      if (formReq.data.data) {
        setProvince(formReq.data.data.province)
        setCity(formReq.data.data.city)
        setPostalCode(formReq.data.data.postal_code)
        setPickUp(formReq.data.data.pick_up)
        setDistrict(formReq.data.data.district)
        console.log(formReq)
        setDetailForms(
          formReq.data.data.form_details.map(function (detail: any) {
            return {
              index: detail.id,
              name: detail.fullname,
              grade: detail.grade,
              gender: detail.gender,
              cell_phone: detail.cell_phone,
            }
          })
        )

        if (formReq.data.data.status == 'EXPIRED') {
          setIsRegistered(false)
        }

        if (formReq.data.data.status != 'PAID') {
          const queryParams = new URLSearchParams({
            event_code: 'JABODETABEK',
          }).toString()
          const quota = await axios.get(
            process.env.REACT_APP_BE_URL + '/forms/check_quota?' + queryParams
          )
          if (quota.data.data) {
            setIsQuotaAvailable(quota.data.data)
          }
        }
      } else {
        //check quota
        setIsRegistered(false)
        const queryParams = new URLSearchParams({
          event_code: 'JABODETABEK',
        }).toString()
        const quota = await axios.get(
          process.env.REACT_APP_BE_URL + '/forms/check_quota?' + queryParams
        )
        if (quota.data.data) {
          setIsQuotaAvailable(quota.data.data)
        }
      }

      const data: Registrant = rawResponse.data.result
      setRegistrant({
        mygms_id: data.mygms_id,
        name: data.name,
        birth_date: moment(data.birth_date).format('D MMM Y'),
        cell_phone: data.cell_phone,
        email: data.email,
        registration_status: data.registration_status,
      })
      if (
        data.registration_status?.registered &&
        data.registration_status?.transaction.status == 'PAID'
      ) {
        const queryParams = new URLSearchParams({
          gms_id: data.mygms_id,
          bill_no: data.registration_status.transaction.bill_no,
        }).toString()
        await axios.get(
          process.env.REACT_APP_BE_URL + '/forms/checkPayment?' + queryParams
        )
      }

      if (
        data.registration_status?.registered &&
        data.registration_status?.transaction.status != 'PAID'
      ) {
        setShowConfirm(true)
      }

      const condition1 =
        data.registration_status?.registered &&
        data.registration_status?.transaction.status == 'PAID'
      const condition2 =
        data.registration_status?.registered &&
        data.registration_status?.transaction.status == 'WAITING' &&
        data.registration_status?.transaction.payment_receipt_url != ''
      if (formReq.data && formReq.data.data && (condition1 || condition2)) {
        setChurchLocation(formReq.data.data.church)
      } else {
        setChurchLocation(getChurchLocation(userData.church.church_id))
      }
      if (
        data.registration_status?.registered &&
        data.registration_status.transaction.status != 'CANCELED' &&
        data.registration_status.transaction.status != 'EXPIRED'
      ) {
        //sync data to database, if already exist this not created on DB
        const syncParams = buildParamsForms(userData)
        syncParams.is_sync = true
      } else {
        //check form passing params
        const queryParams = {
          email: data.email,
          gms_id: data.mygms_id,
        }
        const res = await axios.get(
          process.env.REACT_APP_BE_URL +
          '/forms/check?' +
          new URLSearchParams(queryParams).toString()
        )
      }

      setIsLoading(false)
    }

    // ambil data bila sudah login
    if (isUserAuthenticated()) {
      getData()
    } else {
      doRelogin()
    }
  }, [])

  const disclaimer = () => {
    return (
      <p>
        <h4>
          <b>PENTING</b>
        </h4>
        <ul>
          <li>
            Pastikan Anda terdaftar di <b>gereja lokal</b> yang benar.
          </li>
          <li>
            Pembayaran dapat menggunakan E-Wallet <b>Ovo / Shopee Pay / Data</b>
            <br />
            Atau menggunakan Virtual Account{' '}
            <b>(BCA / Mandiri / CIMB / Permata)</b>
          </li>
          <li>
            {' '}
            Melakukan pembayaran tanpa melakukan pendaftaran di anggap tidak
            valid <b>(tidak dapat melakukan refund)</b>
          </li>
        </ul>
      </p>
    )
  }
  return (
    <div
      id="mainRegister"
    // style={{
    //   backgroundImage: "url(" + background + ")",
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover",
    // }}
    // style={{
    //   backgroundImage:
    //     "url(" + (isMobileDevice() ? background_mobile : background) + ")",
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "cover",
    // }}
    >
      {/* modal konfirmasi pendaftaran */}
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Body>{disclaimer()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showTutorial} onHide={() => setShowTutorial(false)}>
        <Modal.Body>
          <p>
            <h4>Pengambilan Tiket Kids Camp</h4>
            <ol>
              <li>
                Buka Menu Apps pada bagian bawah Apps
                <img
                  style={{ width: '100%', padding: '20px' }}
                  src={tutorial_1}
                ></img>
              </li>
              <li>
                Klik Menu Tiket pada bagian atas Apps
                <img
                  style={{ width: '100%', padding: '20px' }}
                  src={tutorial_2}
                ></img>
              </li>
            </ol>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTutorial(false)}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
      {/* page registrasi */}
      <div className="app-content content">
        <div className="content-wrapper">
          {/* disclaimer */}
          {false && !registrant.registration_status?.registered && (
            <div className="card">
              <div className="card-body">{disclaimer()}</div>
            </div>
          )}
          {/* Profile */}
          <Profile
            church_name={userDataParams.church.name ?? ''}
            name={registrant.name}
            birth_date={registrant.birth_date}
            cell_phone={registrant.cell_phone}
            email={registrant.email}
            location={churchLocation}
            gender={userDataParams.gender}
            address={userDataParams.address}
            cg_status={getCg(userDataParams.connect_groups)}
            event_code={eventCodeState}
            title={`Kids Camp ${churchLocation}`}
            showDisclaimer={setShowConfirm}
            setOccupation={setOccupation}
            setProvince={setProvince}
            setDistrict={setDistrict}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setCityCustom={setCityCustom}
            setPickUp={setPickUp}
            setTotal={setTotal}
            setDetailForms={setDetailForms}
            occupation={occupation}
            registrant={registrant}
            province={province}
            district={district}
            postalCode={postalCode}
            city={city}
            cityCustom={cityCustom}
            pickUp={pickUp}
            total={total}
            detailForms={detailForms}
            isRegistered={isRegistered}
          />
          {/* Detail Registrasi */}
          {(registrant.registration_status?.registered && !registrant.registration_status?.transaction?.bill_no?.includes('GMS/EK-JBT/23')) &&
            renderRegistrationStatus(
              registrant.registration_status.transaction
            )}

          {(!registrant.registration_status?.registered ||
            registrant.registration_status.transaction.status === 'EXPIRED' ||
            registrant.registration_status.transaction.status === 'CANCELED' ||
            registrant.registration_status?.transaction?.bill_no?.includes('GMS/EK-JBT/23')) && (
              <div className="form-group">
                <button
                  onClick={handleRegister}
                  disabled={isLoading}
                  className="btn btn-warning w-100"
                >
                  Daftar
                </button>
              </div>
            )}
          <div className="text-center mt-4 card pt-2 pb-2">
            Ada kendala atau pertanyaan?
            <br />
            Silahkan hubungi hotline kami di:
            <br />
            <a href="https://wa.me/6281388113095?target=_blank">
              0813-8811-3095 (Kak Mariska)
            </a>{' '}
            <a href="https://wa.me/6281383027686?target=_blank">
              0813-8302-7686 (Kak Sisca)
            </a>{' '}
            (chat only)
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterPage
