import React, { useEffect, useState } from 'react'
import { Button, Form, FormControl, Modal } from 'react-bootstrap'
import { DetailForm } from '../interfaces/detail_form'

type Prop = {
  setDetailForm: (data: any) => void
  detailForm: DetailForm
  isShowAddEdit: boolean
  setIsShowAddEdit: (data: any) => void
  index: number
}
const boldText = {
  fontWeight: 'bold',
  fontSize: '14px',
} as const
const ModalAddDetail: React.FC<Prop> = (data: Prop) => {
  const nameChangeHandler = (e: any) => {
    const detailForm = data.detailForm
    detailForm.name = e.target.value
    data.setDetailForm(detailForm)
  }
  const gradeChangeHandler = (e: any) => {
    const detailForm = data.detailForm
    detailForm.grade = e.target.value
    data.setDetailForm(detailForm)
  }
  const genderChangeHandler = (e: any) => {
    const detailForm = data.detailForm
    detailForm.gender = e.target.value
    data.setDetailForm(detailForm)
  }
  const cellphoneChangeHandler = (e: any) => {
    const detailForm = data.detailForm
    detailForm.cell_phone = e.target.value
    data.setDetailForm(detailForm)
  }
  return (
    <Modal
      show={data.isShowAddEdit}
      onHide={() => data.setIsShowAddEdit(false)}
    >
      <Modal.Body>
        <p>
          <h4>Data anak ke {data.index}</h4>
        </p>
        <div className="form-group">
          <label style={boldText}>Nama Anak</label>
          <div>
            <FormControl
              type="text"
              className=""
              placeholder="Input Nama Anak Anda"
              value={data.detailForm.name}
              onChange={nameChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label style={boldText}>Grade Anak</label>
          <div>
            <Form.Select
              value={data.detailForm.grade}
              onChange={gradeChangeHandler}
              aria-label="Pilih Grade Anak"
            >
              <option selected value="" disabled>
                Pilih Grade Anak
              </option>
              <option value="4">Kelas 4</option>
              <option value="5">Kelas 5</option>
              <option value="6">Kelas 6</option>
              <option value="7">Kelas 7</option>
            </Form.Select>
          </div>
        </div>
        <div className="form-group">
          <label style={boldText}>Jenis Kelamin Anak</label>
          <Form.Select
            value={data.detailForm.gender}
            onChange={genderChangeHandler}
            aria-label="Input Jenis Kelamin Anak"
          >
            <option selected value="" disabled>
              Pilih Jenis Kelamin
            </option>
            <option value="Male">Laki - Laki</option>
            <option value="Female">Perempuan</option>
          </Form.Select>
        </div>

        <div className="form-group">
          <label style={boldText}>Handphone Anak</label>
          <div>
            <FormControl
              type="text"
              className=""
              placeholder="Input Handphone Anak Anda"
              value={data.detailForm.cell_phone}
              onChange={cellphoneChangeHandler}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => data.setIsShowAddEdit(false)}>
          Submit
        </Button>
        <Button
          variant="secondary"
          onClick={() => data.setIsShowAddEdit(false)}
        >
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalAddDetail
