import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Form, FormControl } from 'react-bootstrap'
import { randomUUID } from 'crypto'
import { DetailForm } from '../interfaces/detail_form'
import ModalAddDetail from './ModalAddDetail'

const boldText = {
  fontWeight: 'bold',
  fontSize: '14px',
} as const

type Prop = {
  isDataPresent: boolean
  setDetailForm: (data: any) => void
  detailForm: DetailForm
  index: number
  isRegistered: boolean
}

const DetailItem: React.FC<Prop> = (data: Prop) => {
  const [isShowAddEdit, setIsShowAddEdit] = useState(false)
  return (
    <>
      <ModalAddDetail
        setDetailForm={data.setDetailForm}
        detailForm={data.detailForm}
        isShowAddEdit={isShowAddEdit}
        setIsShowAddEdit={setIsShowAddEdit}
        index={data.index + 1}
      />
      {data.isDataPresent && (
        <li>
          <div style={{ marginTop: '10px' }}>
            <b> Nama :</b>
            {data.detailForm.name}
          </div>
          <div>
            <b> Grade :</b> {data.detailForm.grade}
          </div>
          <div>
            <b>Jenis Kelamin : </b>
            {data.detailForm.gender}
          </div>
          <div>
            <b>Handphone :</b> {data.detailForm.cell_phone || '-'}
          </div>
          <div className="d-flex" style={{ gap: '10px', marginBottom: '10px' }}>
            {!data.isRegistered && (
              <button
                className="btn btn-warning"
                onClick={() => setIsShowAddEdit(true)}
              >
                Edit
              </button>
            )}
          </div>
        </li>
      )}
      {!data.isDataPresent && (
        <li>
          <label style={boldText}>Anak ke {data.index + 1}</label>
          <div style={{ marginBottom: '10px' }}>
            <button
              className="btn btn-warning"
              onClick={() => setIsShowAddEdit(true)}
            >
              Tambah Detail
            </button>
          </div>
        </li>
      )}
    </>
  )
}
export default DetailItem
