import React from "react";
import { Link } from "react-router-dom";

type Props = {
  currentPage: string;
};

export default function Header(props: Props) {
  const renderLinks = () => {
    const menus = [
      {
        url: "admin",
        text: "Konfirmasi Pembayaran",
      },
      {
        url: "admin/registrants",
        text: "Daftar Peserta",
      },
      {
        url: "admin/event_detail",
        text: "Event Detail",
      },
    ];
    return menus.map((menu) => {
      const isActive = props.currentPage === menu.url;
      return (
        <li
          className={`nav-item sidebar-group-active ${
            isActive ? "active" : ""
          }`}
        >
          <Link
            to={`/${menu.url}`}
            className="nav-link d-flex align-items-center"
          >
            <span>{menu.text}</span>
          </Link>
        </li>
      );
    });
  };
  return (
    <div>
      <nav className="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center">
        <div className="navbar-container d-flex content">
          <ul className="nav navbar-nav align-items-center ml-auto">
            <li className="nav-item dropdown dropdown-user">
              <a
                href="javscript:void(0)"
                className="nav-link dropdown-toggle dropdown-user-link"
                data-toggle="dropdown"
              >
                <div className="user-nav d-sm-flex d-none">
                  <span className="user-name font-weight-bolder">User</span>
                  <span className="user-status">Admin</span>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <a href="/admin/logout" className="dropdown-item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-power mr-50"
                  >
                    <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                    <line x1="12" y1="2" x2="12" y2="12"></line>
                  </svg>{" "}
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div className="horizontal-menu-wrapper">
        <div className="header-navbar navbar-expand-sm navbar navbar-horizontal floating-nav navbar-light navbar-shadow menu-border">
          <div
            className="navbar-container main-menu-content"
            data-menu="menu-container"
          >
            <ul
              id="main-menu-navigation"
              className="nav navbar-nav"
              data-menu="menu-navigation"
            >
              {renderLinks()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
