import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Form, FormControl } from 'react-bootstrap'
import { randomUUID } from 'crypto'
import Detail from './Detail'
import { DetailForm } from '../interfaces/detail_form'

type Prop = {
  name: string
  cell_phone: string
  birth_date: string
  email: string
  gender: string
  cg_status: string
  address: string
  location: string
  title?: string
  event_code: string
  church_name: string
  showDisclaimer: (data: boolean) => void
  setOccupation: (data: string) => void
  setProvince: (data: string) => void
  setDistrict: (data: string) => void
  setPostalCode: (data: string) => void
  setCity: (data: string) => void
  setCityCustom: (data: string) => void
  setPickUp: (data: string) => void
  setTotal: (data: any) => void
  setDetailForms: (data: any) => void
  occupation: string
  registrant: any
  province: string
  district: string
  postalCode: string
  city: string
  cityCustom: string
  pickUp: string
  total: Number
  detailForms: Array<DetailForm>
  isRegistered: boolean
}
const boldText = {
  fontWeight: 'bold',
  fontSize: '14px',
} as const

const eventData = [
  {
    event_code: 'JABODETABEK',
    event_name: 'KIDS CAMP 2024 - 20 Juni 2024',
    event_description: 'Selamat Datang di Before 30 Kids Camp',
    event_closing: '12 Juni 2024',
    event_pre_register: 'Selasa, 23 April 2024, 10.00 WIB',
  },
]
const flex = {
  display: 'flex',
} as const
const Profile: React.FC<Prop> = (data: Prop) => {
  const occupationChangeHandler = (e: any) => {
    data.setOccupation(e.target.value)
  }

  const provinceChangeHandler = (e: any) => {
    data.setProvince(e.target.value)
  }
  const districtChangeHandler = (e: any) => {
    data.setDistrict(e.target.value)
  }
  const postalCodeChangeHandler = (e: any) => {
    data.setPostalCode(e.target.value)
  }
  const cityChangeHandler = (e: any) => {
    data.setCity(e.target.value)
  }
  const cityCustomChangeHandler = (e: any) => {
    data.setCityCustom(e.target.value)
  }
  const pickUpChangeHandler = (e: any) => {
    data.setPickUp(e.target.value)
  }
  const totalChangeHandler = (e: any) => {
    data.setTotal(e.target.value)
    if (!isNaN(e.target.value) && e.target.value != 0) {
      if (e.target.value > data.detailForms.length) {
        const newData = []
        for (
          let index = 0;
          index < e.target.value - data.detailForms.length;
          index++
        ) {
          newData.push({
            index: index + 1,
            name: '',
            grade: '',
            gender: '',
            cell_phone: '',
          })
        }
        data.setDetailForms([...data.detailForms, ...newData])
      } else {
        const cloneDetailForm = [...data.detailForms]
        data.setDetailForms(cloneDetailForm.slice(0, e.target.value))
      }
    }
  }

  return (
    <div className="card">
      <h1 className="text-center mb-4 mt-4" style={{ fontSize: '22px' }}>
        {data.title}
      </h1>

      <div className="card-body">
        <div className="form-group">
          <div>
            <div style={boldText}>Registrasi</div>
            <div style={flex}>
              <div style={{ width: '100%' }}>
                {eventData.map((d) => (
                  <div>{d.event_description}</div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label style={boldText}>Nama Orang Tua/Wali</label>
          <div>{data.name}</div>
        </div>
        <div className="form-group">
          <label style={boldText}>No Handphone Orang Tua/Wali</label>
          <div>{data.cell_phone}</div>
        </div>
        <div className="form-group">
          <label style={boldText}>Email Orang Tua/Wali</label>
          <div>{data.email}</div>
        </div>
        <div className="form-group">
          <label style={boldText}>Gereja Lokal</label>
          <div>{data.church_name}</div>
        </div>
        <div className="form-group">
          <label style={boldText}>Alamat Domisili</label>
          <div>{data.address}</div>
        </div>
        <div className="form-group">
          <label style={boldText}>Provinsi Domisili</label>
          <div>
            {data.isRegistered && <div>{data.province}</div>}
            {!data.isRegistered && (
              <FormControl
                type="text"
                className=""
                placeholder="Input Provinsi Domisili Anda"
                value={data.province}
                onChange={provinceChangeHandler}
              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label style={boldText}>Kecamatan Domisili</label>
          <div>
            {data.isRegistered && <div>{data.district}</div>}
            {!data.isRegistered && (
              <FormControl
                type="text"
                className=""
                placeholder="Input Kecamatan Domisili Anda"
                value={data.district}
                onChange={districtChangeHandler}
              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label style={boldText}>Kode Pos</label>
          <div>
            {data.isRegistered && <div>{data.postalCode}</div>}
            {!data.isRegistered && (
              <FormControl
                type="text"
                className=""
                placeholder="Input Kode Pos Domisili Anda"
                value={data.postalCode}
                onChange={postalCodeChangeHandler}
              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label style={boldText}>Kota Domisili</label>
          {data.isRegistered && <div>{data.city}</div>}
          {!data.isRegistered && (
            <Form.Select
              value={data.city}
              onChange={cityChangeHandler}
              aria-label="Kota Domisili"
            >
              <option selected value="" disabled>
                Pilih Kota Domisili
              </option>
              <option value="Jakarta Utara">Jakarta Utara</option>
              <option value="Jakarta Timur">Jakarta Timur</option>
              <option value="Jakarta Selatan">Jakarta Selatan</option>
              <option value="Jakarta Barat">Jakarta Barat</option>
              <option value="Jakarta Pusat">Jakarta Pusat</option>
              <option value="Tangerang">Tangerang</option>
              <option value="Bogor">Bogor</option>
              <option value="Cikarang">Cikarang</option>
              <option value="Kelapa Gading">Kelapa Gading</option>
              <option value="Bekasi">Bekasi</option>
              <option value="Bandung">Bandung</option>
              <option value="Cirebon">Cirebon</option>
              <option value="Sukabumi">Sukabumi</option>
              <option value="Others">Others</option>
            </Form.Select>
          )}
        </div>
        {data.city == 'Others' && (
          <div className="form-group">
            <FormControl
              type="text"
              className=""
              placeholder="Input Kota Domisili Anda"
              value={data.cityCustom}
              onChange={cityCustomChangeHandler}
            />
            )
          </div>
        )}
        <div className="form-group">
          <label style={boldText}>Penjemputan</label>
          {data.isRegistered && <div>{data.pickUp}</div>}
          {!data.isRegistered && (
            <Form.Select
              value={data.pickUp}
              onChange={pickUpChangeHandler}
              aria-label="Penjemputan"
            >
              <option selected value="" disabled>
                Pilih Penjemputan
              </option>
              <option value="Dijemput dan Diantar ke Green Forest">Dijemput dan Diantar ke Green Forest</option>
              <option value="Naik Bis">Naik Bis</option>
            </Form.Select>
          )}
        </div>

        {!data.isRegistered && (
          <div className="form-group">
            <label style={boldText}>Jumlah Anak</label>
            <FormControl
              type="number"
              className=""
              placeholder="Input Jumlah Anak"
              value={data.total.toString()}
              onChange={totalChangeHandler}
            />
          </div>
        )}
        <Detail
          isRegistered={data.isRegistered}
          setDetailForms={data.setDetailForms}
          detailForms={data.detailForms}
        />

        <div className="form-group">
          {
            <>
              <a
                href="javascript:void(0)"
                rel="noreferrer"
                onClick={() => data.showDisclaimer(true)}
              >
                Lihat Disclaimer
              </a>
              <br />
              <br />
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default Profile
