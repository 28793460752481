import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Form, FormControl } from 'react-bootstrap'
import { randomUUID } from 'crypto'
import DetailItem from './DetailItem'
import { DetailForm } from '../interfaces/detail_form'

const boldText = {
  fontWeight: 'bold',
  fontSize: '14px',
} as const

type Prop = {
  setDetailForms: (data: any) => void
  detailForms: Array<DetailForm>
  isRegistered: boolean
}

const Detail: React.FC<Prop> = (data: Prop) => {
  const setDetailForm = (d: DetailForm) => {
    const itemIndex = data.detailForms.findIndex(
      (form) => form.index == d.index
    )
    if (itemIndex == -1) return
    let detailForms = data.detailForms
    detailForms[itemIndex] = d
    data.setDetailForms([...detailForms])
  }

  const isDataPresent = (data: DetailForm) => {
    return (
      data.name != '' ||
      data.gender != '' ||
      data.grade != '' ||
      data.cell_phone != ''
    )
  }

  return (
    <div className="form-group">
      <label style={boldText}>Daftar Anak</label>
      <ol>
        {data.detailForms.map((detailForm, index) => {
          return (
            <DetailItem
              isRegistered={data.isRegistered}
              isDataPresent={isDataPresent(detailForm)}
              setDetailForm={setDetailForm}
              detailForm={detailForm}
              index={index}
            />
          )
        })}
      </ol>
    </div>
  )
}
export default Detail
